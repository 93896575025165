let qcu = function ($) {

    let $body = $('body');

    /**
     * Run answers
     */
    let onCkecked = function () {
        $('body').on('change', '#module .qcu input[type="radio"]', function (e) {
            e.preventDefault();

            $(this).closest('.qcu').find('.container').removeClass('checked');
            $(this).closest('.container').addClass('checked');

            let $items = $body.find('#module .qcu input[type="radio"]');
            let $btnAnswers = $body.find('#module .qcu .answers a');
            let itemsLength = $items.length;
            let statusAnswer = false;
            let $next = $body.find('#module > section.main > .content > .next');

            $items.each(function (k) {
                if (this.checked) statusAnswer = true;
                if (k === itemsLength - 1 && statusAnswer) {
                    $btnAnswers.removeClass('disabled');
                    $next.removeClass('disabled');
                    saveAnswersUsers();
                } else {
                    $btnAnswers.addClass('disabled');
                    $next.addClass('disabled');
                }
            });
        });

        $('body').on('change', '#module .qcu-multi input[type="radio"]', function (e) {
            e.preventDefault();

            $(this).closest('div').next().find('.container').removeClass('checked');
            $(this).closest('div').prev().find('.container').removeClass('checked');
            $(this).closest('.container').addClass('checked');

            let $blcRadio = $body.find('#module .qcu-multi .blc-rd');
            let $btnAnswers = $body.find('#module .qcu-multi .answers a');
            let blcRadioLength = $blcRadio.length;
            let $next = $body.find('#module > section.main > .content > .next');

            let statusBlcRadio = true;
            $blcRadio.each(function (kBlc) {
                let statusAnswer = false;
                let $items = $(this).find('input[type="radio"]');
                let itemsLength = $items.length;
                $items.each(function (kItem) {
                    if (this.checked) statusAnswer = true;

                    if (kItem === itemsLength - 1 && !statusAnswer) {
                        statusBlcRadio = false;
                    }

                    if (kItem === itemsLength - 1 && kBlc === blcRadioLength - 1 && statusAnswer && statusBlcRadio) {
                        $btnAnswers.removeClass('disabled');
                        $next.removeClass('disabled');
                        saveAnswersUsers(true);
                    } else {
                        $btnAnswers.addClass('disabled');
                        $next.addClass('disabled');
                    }
                });
            });
        });
    };

    /**
     * Run answers
     */
    let runAnswers = function () {
        $('body').on('click', '.qcu .answers a, .qcu-multi .answers a', function (e) {
            e.preventDefault();
            let _this = this;

            if ($(_this).hasClass('disabled')) return false;

            $(_this).fadeOut(function () {
                $(_this).next().fadeIn();
            });

        });
    };

    /**
     * Save Answers Users
     */
    let saveAnswersUsers = function (multi) {

        let tabAnswers = [];
        let $inputAnswersUser;
        let $items;

        if (multi) {
            $inputAnswersUser = $body.find('#module .qcu-multi input.answers-user');
            $items = $body.find('#module .qcu-multi input[type="radio"]');
        } else {
            $inputAnswersUser = $body.find('#module .qcu input.answers-user');
            $items = $body.find('#module .qcu input[type="radio"]');
        }

        let itemsLength = $items.length;

        let cpt = 0;
        $items.each(function (k) {
            if (this.checked) {
                tabAnswers[cpt] = {};
                tabAnswers[cpt].id = $(this).data('id');
                tabAnswers[cpt].title = $(this).val();
                tabAnswers[cpt].name = $(this).attr('name');
                cpt++;
            }

            if (k == itemsLength - 1) {
                $inputAnswersUser.val(JSON.stringify(tabAnswers)).trigger('change');
            }
        });
    };

    return {
        init: function () {
            onCkecked();
            runAnswers();
        }
    };
}(jQuery);

$(document).ready(function () {
    qcu.init();
});